import {Plugin} from "@ckeditor/ckeditor5-core";
import {toWidget, Widget} from "@ckeditor/ckeditor5-widget";

import "./../../theme/index.css";

import {GetCallback} from "@ckeditor/ckeditor5-utils";

import type {
    DowncastAttributeEvent,
    DowncastWriter,
    Element,
    ViewContainerElement,
    ViewElement,
    ViewUIElement
} from "@ckeditor/ckeditor5-engine";
import {FileUploaderConfig} from "../../typings/fileuploaderconfig";
import {FileUploaderDefaultConfig} from "../fileuploaderdefaultconfig";
import GoogleDriveIcon from "../../theme/icons/google_drive.png"

export class FileUploaderUI extends Plugin {
    private readonly _sizeUnits: string[] = ["bytes", "KB", "MB", "GB"];
    private readonly BOX_ATTRIBUTES: string[] = [
        "file-id",
        "file-title",
        "file-size",
        "file-type",
        "file-iconurl",
        "author-id",
        "created-at",
        "upload-kind"
    ];
    private config: FileUploaderConfig | undefined;

    public static get pluginName(): "FileUploaderUI" {
        return "FileUploaderUI";
    }

    public static get requires(): any {
        return [FileUploaderUI, Widget] as const;
    }

    public init(): void {
        this._defineSchema();
        this._defineConverters();
        this.config = this.editor.config.get("file_uploader") || new FileUploaderDefaultConfig();

        this.editor.editing.downcastDispatcher.on<DowncastAttributeEvent>(
            "attribute:upload-status:ck-file-box",
            this._uploadStatusChange
        );
        this.editor.editing.downcastDispatcher.on<DowncastAttributeEvent>(
            "attribute:src:ck-file-box",
            this._uploadSrcChange
        );

        this.editor.listenTo(this.editor.editing.view.document, "click", (evt, data) => {
            const target = data.target; // This is the view the user clicked on
            debugger;

            if (target.hasClass("ck-file-download") && target.name === 'i') {
                const box = target.parent.parent.parent;
                const uploadKind = box.getAttribute("upload-kind") as string;
                const downloadUri = box.getAttribute("src") as string;

                this._downloadFile(uploadKind, downloadUri);
            }
        });
    }

    private _dateFormat(date: Date): string {
        // full dttm format with lead zeros
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");

        const hour = date.getHours().toString().padStart(2, "0");
        const minute = date.getMinutes().toString().padStart(2, "0");
        const second = date.getSeconds().toString().padStart(2, "0");

        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    }

    private _sizeAutoConverter(byteSize: number, n: number): string {
        const calculatedSize = byteSize / 1024;
        if (n > this._sizeUnits.length - 1) {
            return `${byteSize.toFixed(2)} ${
                this._sizeUnits[this._sizeUnits.length - 1]
            }`;
        }
        const intergerSize = Math.trunc(calculatedSize);
        if (intergerSize === 0) {
            return '';
        }
        return this._sizeAutoConverter(calculatedSize, n + 1);
    }

    private _defineSchema(): void {
        const schema = this.editor.model.schema;

        schema.register("ck-file-box", {
            inheritAllFrom: "$blockObject",
            allowAttributes: ["class", "src", ...this.BOX_ATTRIBUTES],
        });
    }

    private _defineConverters(): void {
        const conversion = this.editor.conversion;

        conversion.for("upcast").elementToElement({
            model: (viewElem, {writer: viewWriter}) => {
                let attrs: { [key: string]: any } = {};
                for (let key of this.BOX_ATTRIBUTES) attrs[key] = viewElem.getAttribute(key) || "";

                if (viewElem.hasAttribute("src"))
                    attrs["src"] = viewElem.getAttribute("src");

                return viewWriter.createElement("ck-file-box", attrs);
            },
            view: {
                name: "div",
                classes: "ck-file-box",
                attributes: this.BOX_ATTRIBUTES,
            },
        });
        conversion.for("downcast").elementToStructure({
            model: {
                name: "ck-file-box",
                attributes: this.BOX_ATTRIBUTES,
            },
            view: (box, {writer}) => {
                let attrs: { [key: string]: any } = {};
                for (let key of this.BOX_ATTRIBUTES) attrs[key] = box.getAttribute(key) || "";

                if (box.hasAttribute("src"))
                    attrs["src"] = box.getAttribute("src");

                const createdAt: any = attrs["created-at"];
                const dateInnerText =
                    createdAt instanceof Date
                        ? this._dateFormat(createdAt)
                        : "Invalid Date";
                const sizeInnerText = this._sizeAutoConverter(+attrs["file-size"], 0);

                const fileTitle = writer.createRawElement(
                    "div",
                    {class: ["ck-file-title"]},
                    function (domElement) {
                        domElement.innerText = attrs["file-title"];
                    }
                );

                const fileSize = writer.createRawElement(
                    "div",
                    {class: ["ck-file-size"]},
                    function (domElement) {
                        domElement.innerText = sizeInnerText;
                    }
                );

                let fileDownload;
                let iconElementAttribute: { [key: string]: any } = {
                    class: ["ck-file-icon"],
                }
                switch (attrs["upload-kind"]) {
                    case "GOOGLE_DRIVE":
                        iconElementAttribute['file-type'] = "drive";
                        iconElementAttribute.src = attrs["file-iconurl"];

                        fileDownload = writer.createEmptyElement("img", {
                            class: "ck-file-download",
                            src: GoogleDriveIcon
                        });
                        break;
                    default:
                        iconElementAttribute['file-type'] = box.getAttribute("file-type");
                        fileDownload = writer.createEmptyElement("i", {
                            class: "ck-file-download",
                        });
                }

                const iconElement = writer.createEmptyElement("img", iconElementAttribute);

                const fileDownloadBox = writer.createContainerElement("div", {
                    class: "ck-file-download-box",
                }, [fileDownload]);

                const fileCreatedAt = writer.createRawElement(
                    "div",
                    {class: ["ck-file-date"]},
                    function (domElement) {
                        domElement.innerText = dateInnerText;
                    }
                );

                const infoElement = writer.createContainerElement(
                    "div",
                    {class: ["ck-file-info"]},
                    [fileTitle, fileSize, fileDownloadBox, fileCreatedAt]
                );

                const boxElement = writer.createContainerElement(
                    "div",
                    {class: ["ck-file-box"], ...attrs},
                    [
                        iconElement,
                        infoElement,
                    ]
                );

                return toWidget(boxElement, writer, {
                    label: "ck-file-box",
                    hasSelectionHandle: false,
                });
            },
        });
    }

    private _uploadStatusChange: GetCallback<DowncastAttributeEvent> = (
        evt,
        data,
        conversionApi
    ) => {
        const editor = this.editor;
        const modelBox = data.item as Element;

        if (!conversionApi.consumable.consume(data.item, evt.name)) {
            return;
        }

        const uploadStatus = data.attributeNewValue as string;
        const boxElement = editor.editing.mapper.toViewElement(modelBox)! as ViewContainerElement;
        const statusProperty = boxElement.getCustomProperty("status") as string;
        const writer = conversionApi.writer;

        if (!boxElement.hasClass("ck-display-upload-status")) {
            writer.addClass("ck-display-upload-status", boxElement);
        }

        const downloadBox = this._findDownloadBox(boxElement)!;
        const changeClass = (downcastWriter: DowncastWriter) => {
            if (statusProperty) {
                downcastWriter.removeClass(statusProperty, boxElement);
            }
            downcastWriter.setCustomProperty("status", uploadStatus, boxElement);
            downcastWriter.addClass(uploadStatus, boxElement);
        }

        switch (uploadStatus) {
            case "reading":
            case "uploading":
                if (!downloadBox.getChild(1)?.is('element')) {
                    const loadingStructure = this._createLoadingStructure(writer);
                    writer.insert(writer.createPositionAfter(downloadBox.getChild(0)!), loadingStructure);
                    changeClass(writer);
                }
                break;
            case "complete":
                writer.addClass("ck-upload-status-hidden", (downloadBox.getChild(1)! as ViewElement));

                setTimeout(() => {
                    editor.editing.view.change(chgWriter => {
                        chgWriter.remove(downloadBox.getChild(1)!);

                        const completeStructure = this._createCompleteStructure(chgWriter);
                        chgWriter.insert(chgWriter.createPositionAfter(downloadBox.getChild(0)!), completeStructure);
                        changeClass(writer);
                    })
                }, 200)
                break;
        }
    };

    private _uploadSrcChange: GetCallback<DowncastAttributeEvent> = (evt, data, conversionApi) => {
        if (!conversionApi.consumable.consume(data.item, evt.name)) {
            return;
        }

        const viewWriter = conversionApi.writer;
        const element = conversionApi.mapper.toViewElement(data.item as Element)!;

        viewWriter.setAttribute(data.attributeKey, data.attributeNewValue || '', element);
    }

    private _findDownloadBox(elem: ViewContainerElement): ViewElement | undefined {
        const fileInfo = elem.getChild(1) as ViewElement;
        const fileBox = fileInfo.getChild(2) as ViewElement;

        return fileBox;
    }

    private _createLoadingStructure(writer: DowncastWriter): ViewUIElement {
        const masterLoadingDiv = writer.createUIElement('div', {class: 'ck-upload-loading'}, function (domDocument) {
            const domElement = this.toDomElement(domDocument);
            domElement.innerHTML = '<div></div><div></div><div></div><div></div>';

            return domElement;
        });

        return masterLoadingDiv;
    }

    private _createCompleteStructure(writer: DowncastWriter): ViewUIElement {
        const masterCompleteDiv = writer.createUIElement('div', {class: 'ck-upload-complete'}, function (domDocument) {
            const domElement = this.toDomElement(domDocument);
            domElement.innerHTML = '<svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path class="checkmark__circle" fill="none" d="M23.538 12A11.538 11.538 0 0 1 12 23.538 11.538 11.538 0 0 1 .462 12a11.538 11.538 0 0 1 23.076 0z"/><path class="checkmark__check" fill="none" d="m6.508 12.554 3.277 3.323 7.708-7.754"/></svg>';

            return domElement;
        });

        return masterCompleteDiv;
    }

    private _downloadFile(kind: string, url: string) {
        const xhr = new XMLHttpRequest();

        if (kind === 'GOOGLE_DRIVE') {
            window.open(url, "_blank")?.focus();
            return;
        }

        xhr.open("POST", url, true);
        xhr.responseType = "blob";

        xhr.addEventListener("error", () => alert("Can't Download File!"));
        xhr.addEventListener("load", () => {
            const response = xhr.response;
            const contentDisposition = xhr.getResponseHeader("Content-Disposition") ?? "";
            const fileName = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)![1];

            let a = document.createElement('a');
            a.href = window.URL.createObjectURL(response);
            a.download = decodeURI(fileName);
            a.click();
        });

        if (this.config!.headers !== undefined)
            Object.entries(this.config!.headers).forEach(([key, value]) => {
                xhr.setRequestHeader(key, value);
            });

        xhr.send();
    }
}
