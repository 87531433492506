import {FileLoader, UploadAdapter, UploadResponse,} from "@ckeditor/ckeditor5-upload";
import {FileUploaderConfig} from "../typings/fileuploaderconfig";

export class FileUploaderAdapter implements UploadAdapter {
    private readonly loader: FileLoader;
    private readonly config: FileUploaderConfig;
    private xhr: XMLHttpRequest | undefined;

    constructor(loader: FileLoader, config: FileUploaderConfig) {
        this.loader = loader;
        this.config = config;

        this._initRequest();
    }

    private _initRequest(): void {
        this.xhr = new XMLHttpRequest();

        this.xhr.open("POST", this.config.uploadUrl, true);
        this.xhr.responseType = "json";
    }

    private _initListeners(
        resolve: (value: UploadResponse | PromiseLike<UploadResponse>) => void,
        reject: (reason?: any) => void,
        file: File
    ): void {
        const xhr = this.xhr!;
        const genericErrorText = `Couldn't upload file: ${file.name}.`;

        xhr.addEventListener("error", () => reject(genericErrorText));
        xhr.addEventListener("abort", () => reject());
        xhr.addEventListener("load", () => {
            const response = xhr.response;

            if (!response || response.error) {
                reject(response?.error ? response.error.message : genericErrorText);
                return;
            }

            resolve(response);
        });

        if (xhr.upload) {
            xhr.upload.addEventListener("progress", (evt: ProgressEvent) => {
                if (evt.lengthComputable) {
                    this.loader.uploadTotal = evt.total;
                    this.loader.uploaded = evt.loaded;
                }
            });
        }
    }

    private _sendRequest(file: File): void {
        const xhr = this.xhr!;

        const formData = new FormData();
        formData.append("upload", file);

        if (this.config.events?.preProcessData)
            this.config.events?.preProcessData(formData);

        // add headers in config
        if (this.config.headers !== undefined)
            Object.entries(this.config.headers).forEach(([key, value]) => {
                xhr.setRequestHeader(key, value);
            });

        xhr.send(formData);
    }

    async upload(): Promise<UploadResponse> {
        let file = await this.loader.file;
        return await new Promise<UploadResponse>((resolve, reject) => {
            this._initRequest();
            this._initListeners(resolve, reject, (file)!);
            this._sendRequest((file)!);
        });
    }

    abort?(): void {
        if (this.xhr) this.xhr.abort();
    }
}
