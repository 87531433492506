
      import API from "!../../ckeditor5/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../ckeditor5/node_modules/style-loader/dist/runtime/singletonStyleDomAPI.js";
      import insertFn from "!../../ckeditor5/node_modules/style-loader/dist/runtime/insertBySelector.js";
      import setAttributes from "!../../ckeditor5/node_modules/style-loader/dist/runtime/setAttributesWithAttributesAndNonce.js";
      import insertStyleElement from "!../../ckeditor5/node_modules/style-loader/dist/runtime/insertStyleElement.js";
      
      import content, * as namedExport from "!!../../ckeditor5/node_modules/css-loader/dist/cjs.js!../../ckeditor5/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[3].use[2]!./index.css";
      
      

var options = {"attributes":{"data-cke":true}};

;
options.setAttributes = setAttributes;

      options.insert = insertFn.bind(null, "head");
    
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../../ckeditor5/node_modules/css-loader/dist/cjs.js!../../ckeditor5/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[3].use[2]!./index.css";
       export default content && content.locals ? content.locals : undefined;
